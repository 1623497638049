<template>
    <div data-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Cargar archivo
                        <v-spacer></v-spacer>
                        <v-btn href="/storage/carga_masiva_de_stock.xlsx" target="_blank" class="ma-2" tile outlined color="success">
                        <v-icon left>mdi-download-outline</v-icon> Descargar archivo formato excel
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-file-input
                        v-model="files"
                        color="deep-purple accent-4"
                        counter
                        label="File input"
                        multiple
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        >
                            <template v-slot:selection="{ index, text }">
                                <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                                >
                                {{ text }}
                                </v-chip>

                                <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                                >
                                +{{ files.length - 2 }} File(s)
                                </span>
                            </template>
                        </v-file-input>
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            color="blue-grey"
                            class="ma-2 white--text"
                            @click="sendForm"
                        >
                            Subir
                            <v-icon right dark>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    data(){
        return {
            files: [],
            loading: false,
        }
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Inventario", route: "" },
            { title: "Actualización masiva ", route: "" },
        ]);
    },
    methods: {
        sendForm()
        {   this.loading = true;
            this.$nextTick(() => {
                if(this.files.length == 1)
                {
                    let formData = new FormData();
                    // files
                    formData.append("file", this.files[0], this.files[0].name);
                    var vm = this;
                    
                    this.axios({
                        url: 'inventory/stock/bulk_load',
                        data: formData,
                        method: 'POST'
                    }).then( response => {
                        vm.loading = false;
                        vm.$bvToast.toast(
                            'Fueron actualizado con exito: '+response.data.nro_productos+' productos!',
                            {
                                title: `Notificación`,
                                variant: "success",
                                solid: true,
                                toaster: "b-toaster-bottom-center",
                            }
                        );
                    }).catch( error => {
                        vm.loading = false;
                    });
                }
                else{
                    this.loading = false;
                    console.log('no hay archivo');
                }
            })
                
        }
    },
}
</script>